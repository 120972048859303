<template>
  <div class="container">
    <!-- <navigation msg="个人信息"></navigation> -->
    <div class="header">
       <van-icon  @click="goback()" size=".35rem" name="arrow-left"/>
       <div class="title7">查看个人信息</div>
    </div>
    <div class="edit" @click="edit">编辑</div>
    <div class="content">
      <div class="flex">
        <div>
          <div class="name">{{perInfo.staff_name == "" ? "暂无" : perInfo.staff_name}} <img class="icon" src="../../assets/sex_1.png" alt=""> </div>
          <div class="info1"> <span>{{perInfo.status | workStatus}}</span> <span>手机：{{perInfo.mobile== "" || perInfo.mobile == null ? "暂无" :  perInfo.mobile}}</span></div>
        </div>
        <div class="tx">
          <img src="../../assets/user_1.jpg" alt="">
        </div>
      </div>
      <div class="title1">个人信息</div>
      <div class="item2">
          <div class="postTitle">
            <div class="dot"></div>
            <div class="txt">{{perInfo.status | workStatus}}</div>
          </div>
          <div>
          <div class="title"></div>
          <div style="display:flex;margin-top:0.3rem;">
            <div class="item" >
              <img class="imgstyle" src="../../assets/experience.png" alt="">
              <div class="text" v-if="perInfo.years_working">{{perInfo.years_working}}年</div>
              <div class="text" v-else>暂无</div>
            </div>
            <div  class="item">
              <img class="img1" src="../../assets/education.png" alt="">
              <div class="text">{{perInfo.education_background == null ? "暂无" : perInfo.education_background}}</div>
            </div>
            <div  class="item">
              <img class="img2" src="../../assets/age.png" alt="">
              <div class="text" v-if="perInfo.age">{{perInfo.age}}岁</div>
              <div class="text" v-else>暂无</div>
            </div>
          </div>
        </div>
      </div>

      <div class="item2">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">身份证号</div>
        </div>
        <div class="card">{{perInfo.id_no== "" ? "暂无" :perInfo.id_no}}</div>
      </div>

      <div class="item2">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">邮箱</div>
        </div>
        <div class="card">{{perInfo.email== "" ? "暂无" :perInfo.email }}</div>
      </div>

      <div class="item2">
        <div class="postTitle">
          <div class="dot"></div>
          <div class="txt">岗位</div>
        </div>
        <div class="card">{{perInfo.position == "" ? "暂无" : perInfo.position}}</div>
      </div>

      <div class="item2 flex5">
        <div>
           <div class="postTitle">
            <div class="dot"></div>
            <div class="txt">居住所在地</div>
           </div>
           <div class="card">{{perInfo.province}}-{{perInfo.city}}-{{perInfo.district}}</div>
        </div>
        <!-- <van-icon size=".35rem" name="arrow"/> -->
      </div>

      <div class="item2 flex5" @click="viewresume">
        <div>
           <div class="postTitle">
            <div class="dot"></div>
            <div class="txt">个人简历</div>
           </div>
           <div class="card">查看简历</div>
        </div>
        <van-icon size=".35rem" name="arrow"/>
      </div> 

    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
// import navigation from "../../components/navigation/index"
export default {
  // components:{
  //   navigation
  // },
  data(){
    return {
      perInfo:{}
    }
    
  },
  mounted(){
    let id = sessionStorage.getItem("companyId")
    // console.log(id)
    this.$http.post("/user/v1/personal_info/perInfo",{reqType:"perInfo",companyId:id})
    .then((res)=>{
      res = JSON.parse(res.data)
      if(res.code == 0){
        // console.log(res)
        this.perInfo = res.data.perInfo
      }else{
        Toast.fail(res.msg)
      }
      
    })
    .catch((e)=>{console.log(e)})
  },
  methods:{
    goback(){
      this.$router.push("./companyDetail")
    },
    
    viewresume(){
      this.$router.push("./userResume")
    },
    edit(){
      let id = this.perInfo.id
      // console.log(id)
      sessionStorage.setItem("perInfoId",id)
      this.$router.push({path:"./addInfo"})
    }
  }
}
</script>
<style scoped>
.header{
  height:1rem;
 display:flex;
 align-items: center;
 
  background-color: #fff;
  padding:0 0.2rem;
 
}
.title7{
   margin-left:2.5rem;
  font-size: 0.34rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
}
.edit{
  position:absolute;
  right:0.2rem;
  top:0.25rem;
  font-size: 0.3rem;
}
.flex5{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.input1 input{
  border:none;
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  /* font-weight: 600; */
 
  
  background-color: #fff;
  
}
.input1{
  padding-bottom:0.3rem;
  
}
.title4{
  height:0.9rem;
  line-height: 0.9rem;
  font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #676767;
}
.flex1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.01rem solid #ddd;
  margin-bottom:0.2rem;
}
.card{
 margin-top:0.2rem;
  font-size: 0.3rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #222222;
 
}
.item2{
  padding:0.3rem 0;
  border-bottom:0.02rem solid #ddd;
}
.img1{
  width:0.34rem;
  height:0.27rem;
  margin-right:0.1rem;
}
.img2{
  width:0.3rem;
  height:0.25rem;
   margin-right:0.1rem;
}
.text{
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
}
.item{
  display:flex;
  align-items:center;
  margin-right:0.5rem;
}
.imgstyle{
  width:0.3rem;
  height:0.29rem;
  margin-right:0.1rem;
}
.title{
  font-size: 0.3rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #333333;
}
.txt{
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
  margin-left:0.17rem;
 
}
.dot{
  width:0.09rem;
  height:0.09rem;
  background: #FF4900;
  border-radius: 50%;

}
.postTitle{
  display:flex;
  align-items: center;
  
}
.title1{
  margin:0.32rem 0;
font-size: 0.32rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #FF5F19;
}
.info1{
  margin-top:0.2rem;
}
.info1 span{
  font-size: 0.28rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #6F6F6F;
}
.icon{
  width:0.4rem;
  height:0.4rem;
}
.name{
  font-size: 0.46rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}

.flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top:0.4rem;
  border-bottom: 0.01rem solid #ddd;
  padding-bottom:0.3rem
}
.tx{
  width:1.27rem;
  height:1.27rem;
}
.tx img{
  width:1.27rem;
  height:1.27rem;
}
.content{
  margin:0 .4rem
}
.container{
   position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
} 
</style>